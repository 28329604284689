<template>
  <div>
    <!-- Page Header Start -->
    <div class="page-title-header">
      <div class="page-header">
        <b-breadcrumb class="m-0">
          <b-breadcrumb-item
            :to="{
              name: 'DashboardHome',
            }"
          >
            <i class="fa fa-home"></i>
          </b-breadcrumb-item>
          <b-breadcrumb-item>
            預約管理
          </b-breadcrumb-item>

          <b-breadcrumb-item :to="{ name: 'BookingPresetList' }">
            預約設定檔列表
          </b-breadcrumb-item>

          <b-breadcrumb-item active
            >{{
              isEditing ? '編輯預約設定檔' : '新增預約設定檔'
            }}
          </b-breadcrumb-item>
        </b-breadcrumb>
      </div>
    </div>
    <!-- Page Header End -->

    <!-- Form Start -->
    <div class="email-wrapper wrapper">
      <div class="row bg-white">
        <div class="col-md-2 mail-sidebar pt-3">
          <div class="menu-bar">
            <ul class="menu-items">
              <li :class="{ active: step == '', done: bookingPreset.id }" @click="goToStep('')">
                <a>基本設定</a>
                <i class="fa fa-check-circle icon-for-done" aria-hidden="true"></i>
                <i class="fa fa-check-circle-o icon-for-undone " aria-hidden="true"></i>
              </li>
              <li :class="{ active: step == '#fields', done: bookingPreset.config.fields && bookingPreset.config.fields.length > 0, disabled: !bookingPreset.id }" @click="goToStep('#fields')">
                <a>資料集欄位</a>
                <i class="fa fa-check-circle icon-for-done" aria-hidden="true"></i>
                <i class="fa fa-check-circle-o icon-for-undone " aria-hidden="true"></i>
              </li>
              <li :class="{ active: step == '#booking-setting', disabled: !bookingPreset.id }" @click="goToStep('#booking-setting')">
                <a>預約設定</a>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-md-10 main-view">
          <b-overlay :show="showLoading">
            <b-card>
              <!-- 基本資料 section start -->
              <section v-if="step == ''">
                <!-- 欄位部分 Start -->
                <div class="row d-flex mb-4 mb-xl-2">
                  <h4 class="col-12 col-xl-6 mb-3 font-weight-bold">{{ isEditing ? '編輯預約設定檔' : '新增預約設定檔' }}</h4>
                  <div class="col-12 col-xl-8">
                    <validation-error-alert
                      v-if="validationErrors"
                      :errors="validationErrors"
                    ></validation-error-alert>
                    <!-- 名稱 Start -->
                    <b-form-group
                      label-cols="12"
                      label-cols-lg="2"
                      label-size="sm"
                      label="*預約設定檔名稱"
                    >
                      <b-form-input
                        class="mb-2 mr-sm-2 mb-sm-0"
                        type="text"
                        :state="v$.bookingPreset.name.$error ? false: null"
                        v-model="bookingPreset.name"
                      ></b-form-input>
                      <b-form-invalid-feedback :state="!v$.bookingPreset.name.$error">
                        此欄位為必填
                      </b-form-invalid-feedback>
                    </b-form-group>
                    <!-- 名稱 End -->
                    <!-- 描述 Start -->
                    <b-form-group
                      label-cols="12"
                      label-cols-lg="2"
                      label-size="sm"
                      label="預約設定檔說明"
                    >
                      <b-form-textarea
                        class="mb-2 mr-sm-2 mb-sm-0"
                        v-model="bookingPreset.config.description"
                      ></b-form-textarea>
                    </b-form-group>
                    <!-- 描述 End -->
                    <!-- 瀏覽器紀錄 Start -->
                    <b-form-group
                      label-cols="12"
                      label-cols-lg="2"
                      label-size="sm"
                      label="是否開放預約"
                      description="是否允許使用者新增預約"
                    >
                      <b-form-checkbox v-model="bookingPreset.config.is_enabled">啟用</b-form-checkbox>
                    </b-form-group>
                    <!-- 瀏覽器紀錄 End -->
                  </div>
                </div>
                <!-- 欄位部分 End -->
                <!-- 表單底部 Start -->
                <div class="d-flex justify-content-center">
                  <b-button
                    class="mr-3"
                    @click="cancel"
                    variant="outline-danger"
                  >
                    返回
                  </b-button>
                  <b-button
                    @click="submit"
                    variant="success"
                  >
                    儲存
                  </b-button>
                </div>
                <!-- 表單底部 End -->
              </section>
              <!-- 基本資料 section end -->

              <!-- 欄位設定 section start -->
              <section v-if="step == '#fields'">
                <div>
                  <DynamicFormEditor
                    v-if="bookingPreset.id"
                    @output="saveFields"
                    editor-title="預約欄位編輯"
                    :form-type="'booking'"
                    :input-subjects="bookingPreset.config.fields"
                  ></DynamicFormEditor>
                </div>
              </section>
              <!-- 欄位設定 section end -->

              <!-- 預約設定 section start -->
              <section v-if="step == '#booking-setting'">
                <!-- 欄位部分 Start -->
                <div class="row d-flex mb-4 mb-xl-2">
                  <h4 class="col-12 col-xl-6 mb-3 font-weight-bold">預約設定</h4>
                  <div class="col-12 col-xl-8">
                    <validation-error-alert
                      v-if="validationErrors"
                      :errors="validationErrors"
                    ></validation-error-alert>
                    <!-- 填寫後通知 Start -->
                    <b-form-group
                      label-cols="12"
                      label-cols-lg="3"
                      label-size="sm"
                      label="預約後通知方式"
                    >
                      <b-form-checkbox-group
                        v-model="bookingPreset.config.booking_setting.notify"
                        :options="[{ text: 'Line 推播', value: 'line' }, { text: 'Email', value: 'email' }]"
                      ></b-form-checkbox-group>
                    </b-form-group>
                    <!-- 填寫後通知 End -->
                  </div>
                </div>
                <!-- 欄位部分 End -->
                <!-- 表單底部 Start -->
                <div class="d-flex justify-content-center">
                  <b-button
                    class="mr-3"
                    @click="cancel"
                    variant="outline-danger"
                  >
                    返回
                  </b-button>
                  <b-button
                    @click="submit"
                    variant="success"
                  >
                    儲存
                  </b-button>
                </div>
                <!-- 表單底部 End -->
              </section>
              <!-- 預約設定 section end -->
            </b-card>
          </b-overlay>
        </div>
      </div>
    </div>
    <!-- Form End -->

  </div>
</template>

<script>
import { zh } from "vuejs-datepicker/dist/locale";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import bookingApi from "@/apis/booking";
import DynamicFormEditor from '@/components/DynamicFormEditor/DynamicFormEditor.vue';
import { mapGetters } from "vuex";

export default {
  components: {
    DynamicFormEditor,
  },
  setup: () => ({ v$: useVuelidate() }),
  validations() {
    return {
      bookingPreset: {
        name: { required },
      }
    };
  },
  computed: {
    ...mapGetters("route", ["routeQuery"]),
  },
  data() {
    return {
      zh,
      isEditing: false,
      showLoading: false,
      validationErrors: null,
      step: '',
      bookingPreset: {
        name: null,
        config: {
          fields: [],
          description: null,
          booking_setting: {},
        },
      },
    }
  },
  mounted() {
    if (this.$route.name === "BookingPresetEdit") {
      this.isEditing = true;
      this.bookingPreset.id = this.$route.params.id;
      this.fetchBookingPreset();
      this.navigateToStepFromCurrentRoute();
    } else {
      this.isEditing = false;
      this.bookingPreset = {
        name: null,
        config: {
          fields: [],
          description: null,
          booking_setting: {},
        },
      };
    }
  },
  methods: {
    cancel() {
      this.$router.push({ name: 'BookingPresetList', query: this.routeQuery });
    },
    goToStep(step) {
      if (!this.bookingPreset.id) {
        return;
      }

      if (this.step == step) {
        return;
      }

      this.step = step
      if (this.$route.hash !== this.step) {
        this.$router.replace({ hash: this.step });
      }
    },
    navigateToStepFromCurrentRoute() {
      const anchor = this.$route.hash;
      if (anchor) {
        this.goToStep(anchor);
      }
    },
    async submit() {
      const result = await this.v$.$validate();
      if (!result) return false;

      try {
        this.showLoading = true;
        // 編輯表單
        if (this.isEditing) {
          await bookingApi.updateBookingPreset(this.bookingPreset.id, this.bookingPreset);
          this.$swal("儲存成功", "", "success");
        }
        // 新增表單
        else {
          let response = await bookingApi.createBookingPreset({ ...this.bookingPreset});
          this.bookingPreset = response.data.data;
          this.$swal("新增成功", "", "success");
          this.isEditing = true;
        }

        return true;
      } catch (error) {
        console.error(error);
        this.$swal("錯誤", "儲存失敗", "error");
        return false;
      } finally {
        this.showLoading = false;
      }
    },
    async fetchBookingPreset() {
      if (! this.bookingPreset.id) {
        this.$swal("錯誤", "無法取得讀取 ID", "error");
        return;
      }

      try {
        this.showLoading = true;
        let response = await bookingApi.getBookingPreset(this.bookingPreset.id);

        // 補防禦
        if (! response.data.data.config) {
          response.data.data.config = {
            fields: [],
            booking_setting: {},
          };
        }

        this.bookingPreset = response.data.data;

      } catch (error) {
        console.error(error);
        this.$swal("錯誤", "讀取資料錯誤", "error");
        this.cancel();
      } finally {
        this.showLoading = false;
      }
    },
    async saveFields(fields) {
      try {
        this.showLoading = true;

        this.bookingPreset.config.fields = fields;

        await bookingApi.updateBookingPreset(this.bookingPreset.id, this.bookingPreset);
        this.$swal("儲存成功", "", "success");
      } catch (error) {
        console.error(error);
        this.$swal("錯誤", "儲存資料錯誤", "error");
      } finally {
        this.showLoading = false;
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.menu-items > li {
  cursor: pointer;

  &.disabled {
    background-color: #ddd;
    opacity: 0.5;
    pointer-events: none;
  }

  .icon-for-done {
    display: none;
  }

  &.done {
    i {
      color: green;
    }

    .icon-for-done {
      display: unset;
    }

    .icon-for-undone {
      display: none;
    }
  }
}
</style>
